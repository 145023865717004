// Main Style
@import './layout/variable_mixin_function';

// --------------------------------------------------------

/* Vendor */

// --------------------------------------------------------

/* Component */

// --------------------------------------------------------

/* Sections */
.hero {
  $root: &;

  --hero-height: 18.75rem;
  --hero-nav-padding-lr: .5rem;

  @include media-breakpoint-up(md) {
    --hero-height: clamp(21.625rem, 33.85vw, 40.625rem);
    --hero-nav-padding-lr: 2rem;
  }

  @include media-breakpoint-up(lg) {
    --hero-nav-padding-lr: 3.125rem;
  }

  & {
    position: relative;
    background-color: #F5F5F7;
  }

  &__item {
    width: 100%;
    height: 100%;
    transition-timing-function: var(--transition-timing-md);
  }

  &__swiper {
    height: var(--hero-height);

    .swiper-wrapper {
      transition-timing-function: var(--transition-timing-md);
    }

    .swiper-slide {
      overflow: hidden;
    }
  }

  &__swiper__navigation {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: var(--hero-nav-padding-lr);
    padding-right: var(--hero-nav-padding-lr);
    transform: translate(0, -50%);
    pointer-events: none;

    .swiper-nav {
      pointer-events: auto;
    }
  }

  &__swiper__pagination {
    --swiper-pagination-bullet-width: 40px;
    --swiper-pagination-bullet-height: 5px;
    --swiper-pagination-bullet-inactive-color: #{$gray-700};
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-color: #{$blue-900};

    @include media-breakpoint-up(md) {
      --swiper-pagination-bullet-width: 60px;
    }

    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;

    @include media-breakpoint-up(lg) {
      position: absolute;
      z-index: 2;
      bottom: 1.25rem;
      left: 0;
      margin-top: 0;
    }
  }
}

// solution
.solution {
  --content-margin-top: 1.875rem;
  --slide-max-width: 28.75rem;
  --slide-space-between: 1.875rem;
  --pagination-margin-top: 1.875rem;

  @include media-breakpoint-up(md) {
    --sec-margin-top: 6.25rem;
    --sec-margin-bottom: 6.25rem;
  }
  
  @include media-breakpoint-up(lg) {
    --sec-margin-top: 9.375rem;
    --sec-margin-bottom: 9.375rem;
    --content-margin-top: 3.125rem;
    --pagination-margin-top: 3.125rem;
  }

  overflow: hidden;

  &__content {
    position: relative;
    width: 90%;
    max-width: var(--slide-max-width);
    margin-top: var(--content-margin-top);
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
      width: 100%;
      max-width: none;
    }
  }

  &__buttons {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100rem;
    margin-left: auto;
    margin-right: auto;
  }

  &__swiper {
    @include media-breakpoint-up(sm) {
      overflow: visible;
    }

    @include media-breakpoint-up(md) {
      width: 168.05%;
      left: 50%;
      transform: translate(-50%, 0);
    }

    &__wrapper {
      transition-timing-function: var(--transition-timing-md);
    }

    &__pagination {
      margin-top: var(--pagination-margin-top);
    }
  }

  &__slide {
    opacity: 0.6;
    transition: opacity var(--duration-600);

    &.swiper-slide-active,
    &.swiper-slide-duplicate-active {
      opacity: 1;
    }
    
    &.swiper-slide-prev,
    &.swiper-slide-next,
    &.swiper-slide-duplicate-prev,
    &.swiper-slide-duplicate-next {
      @include media-breakpoint-up(xl) {
        opacity: 1;
      }
    }
  }

  &__item {
    position: relative;
    display: block;
    overflow: hidden;
    aspect-ratio: 4 / 3;

    &:hover {
      img {
        transform: scale(1.1);
        transition: var(--duration-800);
      }

      .solution__title {
        transform: translate(0, calc(-50% - .75rem));
      }
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, rgba(#393939, 0.2) 0%, rgba(#22337B, 0.3) 50%, rgba(#0A2DC3, 0.9) 100%);
    }

    img {
      transition: var(--duration-400);
    }
  }

  &__title {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0;
    width: 100%;
    padding: 1rem;
    color: $white;
    text-align: center;
    transform: translate(0, -50%);
    transition: var(--duration-400);
  }

  .swiper-nav {
    position: absolute;
    z-index: 2;
    top: 50%;
  }

  .prev-button {
    left: 0;
    transform: translate(-50%, -50%);
  }

  .next-button {
    right: 0;
    transform: translate(50%, -50%);
  }
}

// news
.news {
  --content-margin-top: 1.875rem;
  --list-margin-top: 1.25rem;
  --buttons-margin-top: 1.25rem;

  @include media-breakpoint-up(md) {
    --sec-margin-top: 6.25rem;
    --sec-margin-bottom: 6.25rem;
    --buttons-margin-top: 1.875rem;
  }
  
  @include media-breakpoint-up(lg) {
    --sec-margin-top: 9.375rem;
    --sec-margin-bottom: 9.375rem;
    --content-margin-top: 3.125rem;
  }

  &__content {
    margin-top: var(--content-margin-top);
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: .625rem;
    margin-top: var(--list-margin-top);

    .news-card {
      border-bottom: 1px solid $black;
    }
  }

  &__buttons {
    margin-top: var(--buttons-margin-top);
  }
}

// contact
.contact {
  --sec-padding-top: 7.75rem;
  --sec-padding-bottom: 6.375rem;
  --text-padding: 1.875rem;
  --text-width: 90%;
  --text-max-width: 18.75rem;
  --text-item-gap: 1.25rem;

  @include media-breakpoint-up(md) {
    --sec-padding-top: 8.5rem;
    --sec-padding-bottom: 8.5rem;
    --text-padding: 3.75rem;
    --text-width: 70%;
    --text-max-width: 43.75rem;
    --text-item-gap: 1.875rem;
  }

  position: relative;
  z-index: 1;
  padding-top: var(--sec-padding-top);
  padding-bottom: var(--sec-padding-bottom);

  &__bg {
    z-index: -1;
  }

  &__text {
    --button-text-color: #{$blue-800};
    --button-bg-color: #{$white};
    --button-text-color-hover: #{$blue-900};
    --aos-transition: var(--duration-1000) var(--transition-timing-md);

    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--text-item-gap);
    width: var(--text-width);
    max-width: var(--text-max-width);
    margin-left: auto;
    margin-right: auto;
    padding: var(--text-padding);
    text-align: center;
    color: $white;
    background-color: rgba($blue-900, 0.9);

    p {
      font-size: var(--font-size-base);

      @include media-breakpoint-up(lg) {
        font-size: var(--font-size-h5);
      }
    }
  }
}

.sec-wrapper {
  position: relative;
  display: flow-root;
}

.sec__bg {
  &--top {
    img {
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &--bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 65%;
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);

    @include media-breakpoint-up(md) {
      height: 55%;
    }

    @include media-breakpoint-up(lg) {
      clip-path: polygon(0 40%, 100% 0, 100% 100%, 0% 100%);
    }
  }
}
